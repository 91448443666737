@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&family=Poppins:wght@400&family=Roboto:wght@300&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.poppins {
  font-family: 'Poppins', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-body {
  height: 100vh;
}

.w-100 {
  width: "100%";
}

.check-label {
  font-size: 10px !important;
}

.collapse {
  transition: all 0.6s ease-in-out;
  width: 58px;
  visibility: visible;
}

.expand {
  transition: all 0.6s ease-in-out;
  width: 250px
}

.main-page-collapse {
  transition: all 0.6s ease-in-out;
  width: 86vw;
  visibility: visible;
}

.main-page-expand {
  transition: all 0.6s ease-in-out;
  width: 97vw
}

.opacity-max {
  animation: opacityMax 0.6s ease-in-out 0s 1 normal forwards;
}

.opacity-min {
  animation: opacityMin 0.4s ease-in-out 0s 1 normal forwards;
}

.hi-flip {
  transition: all 1s ease-in-out;
  transform: rotateY(180deg);
}
input::placeholder{
  font-size: 12px!important;
}
::-webkit-scrollbar-thumb {
  /* width: 5px!important; */
  background-color: #fecaca !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 3px !important;
  margin-top:5px!important;
  padding-top:5px!important;

}


@keyframes opacityMax {
  from {
    opacity: 0;
  }

  to {
    opacity: 1
  }

}

@keyframes opacityMin {
  from {
    opacity: 1;
  }

  to {
    opacity: 0
  }

}

@keyframes expanded {
  0% {
    width: "3vw";
  }

  100% {
    width: "14vw"
  }

}